var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "70%",
            title: "Thời khóa biểu các tuần : " + this.className,
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "2vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "createTabAllSchedule",
              attrs: {
                "label-width": "140px",
                "label-position": "left",
                model: _vm.tabSchduleViewDetail,
              },
            },
            [
              _c(
                "div",
                { staticClass: "table-content row-data" },
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        "highlight-current-row": "",
                        "header-cell-style": _vm.tableHeaderColor,
                        data: _vm.tabSchduleViewDetail,
                        "max-height": _vm.$tableMaxHeight,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "weeknumber",
                          width: "100px",
                          label: "Tuần",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { margin: "0 40%" } },
                                  [_vm._v(_vm._s(scope.row.weeknumber))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "timeApplyWeek",
                          label: "Thời gian áp dụng",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "approve", label: "Trạng thái duyệt" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.listCheckContentday != null ||
                                scope.row.fileList != null
                                  ? _c("el-checkbox", {
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleCheckBoxApprove(
                                            scope.$index,
                                            scope.row
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.approve,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "approve", $$v)
                                        },
                                        expression: "scope.row.approve",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "listCheckContentday",
                          label: "Ngày có nội dung",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "contentSchedule",
                          label: "TKB dạng File,Ảnh",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.listFileOrPicture) +
                                    "\n              "
                                ),
                                _c("el-upload", {
                                  staticClass: "upload-demo",
                                  attrs: {
                                    action: "",
                                    "on-remove": _vm.handleRemove,
                                    "before-remove": _vm.beforeRemove,
                                    "auto-upload": false,
                                    "on-change": _vm.handleChangeFile,
                                    multiple: "",
                                    limit: 5,
                                    "file-list": scope.row.fileList,
                                    "on-preview": _vm.handleFileRequest,
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm.checkPermission(["studentQuality_schedule_update"])
                        ? _c("el-table-column", {
                            attrs: {
                              label: "Tác vụ",
                              align: "center",
                              width: "130",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEditViewDetail(
                                                scope.$index,
                                                scope.row,
                                                2
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Sửa nội dung")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              641151727
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "tab-infor-button" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogByButton("createTabAllSchedule")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v("Đóng")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("UpdateSchedule", {
        ref: "UpdateSchedule",
        attrs: { dialogVisible: _vm.showUpdateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseUpdateMethod()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }